var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tableProjects.length > 0)?_c('v-card',{staticClass:"mb-5 mt-3 left-bordered-primary",attrs:{"elevation":"1"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.tableProjects,"footer-props":{
            'items-per-page-options': [10, 20, 30, 40, -1]
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white","dense":""}},[_c('v-toolbar-title',{staticClass:"black--text"},[_vm._v("Projekty powiązane z Twoim zamówieniem")]),_c('br')],1),_c('p',{staticClass:"text-left ml-4 text-caption"},[_vm._v("Poniżej projekty")]),_c('v-divider')]},proxy:true},{key:"item.orderPosition",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.productName))]),_c('br'),_vm._l((item.variantNames),function(variant){return _c('span',{key:variant,staticClass:"caption"},[_vm._v(" "+_vm._s(variant)+" / ")])})]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.project.status)?_c('span',[_c('v-chip',{staticClass:"white--text",attrs:{"x-small":"","color":_vm.projectStatuses[item.project.status].color}},[_vm._v(" "+_vm._s(_vm.projectStatuses[item.project.status].name)+" ")])],1):_c('span',[_vm._v(" Brak statusu ")]),_c('br'),(item.project.statusDate)?_c('span',{staticClass:"text-caption gray--text"},[_vm._v(_vm._s(_vm._f("moment")(item.project.statusDate,"DD/MM/YY HH:mm")))]):_vm._e()]}},{key:"item.projectLink",fn:function(ref){
        var item = ref.item;
return [(item.adminPanelUrl)?_c('span',{staticStyle:{"cursor":"pointer"}},[_c('v-chip',{attrs:{"small":"","outlined":"","color":"purple lighten-2"}},[_c('a',{attrs:{"target":"_blank","href":item.projectLink}},[_vm._v(" Panel projektu "),_c('v-icon',{attrs:{"color":"purple lighten-2","dark":"","right":""}},[_vm._v(" mdi-link ")])],1)])],1):_vm._e()]}}],null,false,2677581451)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }