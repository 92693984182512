<template>
    <v-container fluid  color="blue-grey lighten-5">
        <confirm ref="confirm" />
        <QtyEditDialog ref="qtyedit" />
        <PlaceOrderDialog v-model="details" :voucher-code="rabatCode" />
        <v-row class="text-center" v-if="isLoading"><p>Trwa ładowanie</p></v-row>
        <v-row class="text-center" v-else>
            <v-col cols="12" lg="9" md="8" sm="7">
               
                <v-data-table
                    :headers="headers"
                    :items="products"
                    :hide-default-footer="false"
                    :items-per-page="20"
                    sort-by="product"
                    class="elevation-1"
                    group-by="category"
                >
                    <template v-slot:top>
                        <v-toolbar flat color="white" dense>
                            <v-toolbar-title class="black--text">Zawartość Twojego koszyka</v-toolbar-title><br/>
                        </v-toolbar>
                        <v-row>
                            <v-col cols="12" md="4">

                                <p class="text-left ml-4 text-caption">
                                    Unikalny numer koszyka:<br/>{{ order.uuid }}
                                </p>
                            </v-col>
                           
                        </v-row>
                        <v-divider ></v-divider>
                    </template>
                    <template v-slot:group.header="{ group, headers, items, isMobile }">
                        
                        <td v-if="group" class="text-start" :class="{'v-data-table__mobile-row': isMobile}" >
                            <strong>{{ items[0].categoryTitle || "Bez grupy" }}</strong>
                            <span v-if="items[0].paidDetails">
                                <br/>{{ items[0].paidDetails }}
                                <v-btn icon x-small title="Zmień wariant produktu" @click="editPaidDetails(items[0].orderPos)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn> 
                            </span>
                        </td>
                        <td v-if="group" :class="{'v-data-table__mobile-row': isMobile}" class="text-start" :colspan="headers.length-3">
                            <v-btn
                                class="ml-2"
                                outlined
                                color="purple lighten-2"
                                x-small
                                @click="addPersonalCartPosition(items[0].orderPos._id)">
                                Dodaj z inną treścią
                            </v-btn>
                        </td>
                        <td v-if="group" :class="{'v-data-table__mobile-row': isMobile}">
                            <div v-if="items[0].neededDetailsCount > 0">
                                <span class="text-caption font-weight-light">Szczegóły produktu {{ items[0].providedDetailsCount }} / {{ items[0].neededDetailsCount }}</span>
                                <v-icon small class="ml-2" @click="gotoDetails(items[0].orderPos._id)">mdi-pencil</v-icon>
                            </div>
                        </td>
                        <td v-if="group" :class="{'v-data-table__mobile-row': isMobile}">
                            <v-btn
                                v-if="isMobile"
                                class=" ml-2"
                                outlined
                                color="purple lighten-2"
                                x-small
                                :disabled="editBlock"
                                @click="deleteCartPos(items[0].orderPos._id)"
                            >Usuń produkt</v-btn>
                            <v-icon v-else small @click="deleteCartPos(items[0].orderPos._id)">mdi-delete</v-icon>
                        </td>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="!item.variant && item.neededDetailsCount > 0">
                            <span class="text-caption font-weight-light">Szczegóły produktu {{ item.providedDetailsCount }} / {{ item.neededDetailsCount }}</span>
                            <v-icon small class="ml-2" @click="gotoDetails(item.orderPos._id)">mdi-pencil</v-icon>
                        </div>
                        <v-icon v-if="!item.variant" small @click="deleteCartPos(item.orderPos._id)">mdi-delete</v-icon>
                    </template>
                    <template v-slot:item.qty="{ item }">
                        <span style="cursor:pointer;" @click="editVariantQty(item.orderPos, item.variant)">
                            <span v-if="item.isPermanent">{{ item.qty }}</span>
                            <span v-else>
                                <v-chip color="purple lighten-2" class="white--text" small style="cursor:pointer;" > {{ item.qty }} </v-chip>
                                <span class="text-caption grey--text ml-1">(edytuj)</span>
                            </span>
                        </span>
                    </template>
                    <template v-slot:item.texts="{ item }">
                        <v-icon v-if="!item.texts" color="grey" right >
                            mdi-drag-horizontal-variant
                        </v-icon>
                        <span v-else @click="editTextsInVariant(item.orderPos, item.variant)" style="cursor:pointer">
                            <v-icon
                                :color="item.texts == 'ok' ? 'teal accent-4' : 'red lighten-2'"
                                dark
                                right>
                                {{ item.texts == 'ok' ? 'mdi-check' : 'mdi-alert-box-outline'}}
                            </v-icon>
                            <span class="text-caption grey--text">(edytuj)</span>
                        </span>
                        
                    </template>
                    <template v-slot:item.guestsList="{ item }">
                        <v-icon v-if="!item.guestsList" color="grey" right >
                            mdi-drag-horizontal-variant
                        </v-icon>
                        <span v-else @click="editGuestListInVariant(item.orderPos, item.variant)" style="cursor:pointer">
                            <v-icon
                                :color="item.guestsList == 'ok' ? 'teal accent-4' : 'red lighten-2'"
                                dark
                                right
                            >
                                {{ item.guestsList == 'ok' ? 'mdi-check' : 'mdi-alert-box-outline'}}
                            </v-icon>
                            <span class="text-caption grey--text">(edytuj)</span>
                        </span>
                        
                    </template>
                    <template v-slot:no-data>
                        Brak produktów w Twoim koszyku
                    </template>
                </v-data-table>
                
                <ProjectTable :orderContents="orderContents" />
                                                            
                <CartExtraInfoVue />
                          
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="5" class="text-left d-flex flex-column">
                <v-card>
                    <v-card-text>
                        <div>Wartość Twojego zamówienia</div>
                        <p class="text-h4 mb-0 text--primary">
                            <span class="green--text" v-if="voucher && isVoucherValid && voucher.type=='amount'">{{parseFloat(totalPrice) - parseFloat(voucher.value)}} zł</span> 
                            <span v-bind:class="{'text-subtitle-1 font-weight-light text-decoration-line-through':voucher}">{{ totalPrice }}</span> 
                        </p>
                    </v-card-text>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-diamond-stone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Rabaty</v-list-item-title>
                            <v-list-item-subtitle class="text-wrap">Jeśli posadasz kod rabatowy wpisz go poniżej</v-list-item-subtitle>
                            <v-text-field
                                label="Kod rabatowy"
                                required
                                dense
                                hide-details
                                outlined
                                class="mt-1"
                                v-model="rabatCode"
                                @input="onChange"
                                :loading=isRabatLoading
                            ></v-text-field>
                            <span v-if="!isVoucherValid" class="red--text" style="font-size:12px">Błędny kod rabatowy</span>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-truck-fast-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Wysyłka <span class="font-weight-medium text-subtitle-1 primary--text" v-if="order.delivery.typeId">{{getDelveryPrice(order.delivery.typeId)}} zł</span></v-list-item-title>
                            <v-list-item-subtitle>Wybierz sposób dostawy</v-list-item-subtitle>
                            <v-select class="mt-1" hide-details dense outlined :items=deliveryTypes label="Przewoźnik" item-text="name" item-value="_id" @change="changeDelivery" v-model="order.delivery.typeId"></v-select>
                        </v-list-item-content>
                    </v-list-item>

                                                                
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-fast-forward-10</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">Czas realizacji 7-10 dni roboczych</v-list-item-title>
                            <v-list-item-subtitle class="text-wrap">(od momentu akceptacji projektu)</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-star-check-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap red--text text-darken-4">Usługa Express: czas realizacji 3-4 dni robocze</v-list-item-title>
                            <v-list-item-subtitle class="text-wrap red--text">(do wybrania po złożeniu zamówienia)</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                                                                      
                                 
                                          
                                                           
                                           
                                             
                                                                                                                       
                                                                                                                           
                                              
                                  
                              

                    
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon>mdi-shopping-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                                <v-btn outlined color="primary" v-if="backUrl" @click="backToShop" small>
                                    Dodaj kolejny produkt
                                    <v-icon small>mdi-plus</v-icon>
                                </v-btn>
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-wrap">(kliknij aby wrocić do sklepu)</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"/>
                    <v-card-actions class="my-2">
                        <v-btn class="px-4" block large color="primary" :disabled="products.length == 0" @click="placeOrder">
                            Złóż zamówienie
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="5" class="text-left d-flex flex-column">
                
            </v-col>
                
        </v-row>
        
    </v-container>
</template>

<script>

import config from '../../config';
import Api from '../../CustomerApi';
import Confirm from '../common/Confirm.vue';
import TextEditDialog from '../common/TextEditDialog.vue';
import GuestListEditDialog from '../common/GuestListEditDialog.vue';
import QtyEditDialog from '../common/QtyEditDialog.vue';
import PlaceOrderDialog from './PlaceOrderDialog.vue';
import CustomCartPosDialog from '../common/CustomCartPosDialog.vue';
import PaidDetailsSelectorDialog from '../common/PaidDetailsSelectorDialog.vue';
import ProjectTable from './ProjectTable.vue';
import CartExtraInfoVue from './CartExtraInfo.vue';

const moneyFormat = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });
let timeout = null;

export default {
    components: {
        Confirm,
        QtyEditDialog,
        PlaceOrderDialog,
        ProjectTable,
        CartExtraInfoVue
    },

    data: () => ({
        isLoading: true,
        order: null,
        details: false,

        backUrl: null,

        headers: [
            {
                text: 'Produkt',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Cena jednostkowa', value: 'price',  sortable: false  },
            { text: 'Zamówiona ilość', value: 'qty',  sortable: false  },
            { text: 'Wartość', value: 'wholePrice',  sortable: false  },
            { text: 'Treści', value: 'texts',  sortable: false  },
            { text: 'Lista gości', value: 'guestsList',  sortable: false  },
            { text: '', value: 'actions', sortable: false },
            
        ],
        orderContents: [],
        deliveryTypes:[],
        features: [],
        rabatCode:"",
        isVoucherValid:true,
        isRabatLoading:false,
        voucher: null
        
    }),

    computed: {
        totalPrice() {
            let total = 0;
            for (let i = 0; i < this.orderContents.length; i++) {
                const orderPos = this.orderContents[i];
                total += orderPos.qty * orderPos.price;
                if (Array.isArray(orderPos.surcharges) && orderPos.surcharges.length) {
                    for (let z = 0; z < orderPos.surcharges.length; z++) {
                        total += orderPos.surcharges[z].value;
                    }
                } else if (orderPos.surcharge && orderPos.surcharge.value) total += orderPos.surcharge.value;
            }
            return moneyFormat.format(total);
        },

        products() {
            let p = [];
            for (let i = 0; i < this.orderContents.length; i++) {
                const orderPos = this.orderContents[i];
                let neededDetailsCount = Object.keys(orderPos.details || {}).length;
                let providedDetailsCount = Object.keys(orderPos.details || {}).filter(key => orderPos.details[key] != null).length;
                let paidDetailsTxt = "";
                for (let featureId in (orderPos.paidDetails || {})) {
                    let feature = this.features.find(f => f._id == featureId);
                    if (!feature) continue;

                    paidDetailsTxt += feature.name + ": " + orderPos.paidDetails[featureId];
                }

                if (Array.isArray(orderPos.variants) && orderPos.variants.length) {
                    for (let y = 0; y < orderPos.variants.length; y++) {
                        const variant = orderPos.variants[y];
                        p.push({
                            orderPos,
                            variant,
                            neededDetailsCount,
                            providedDetailsCount,
                            name: variant.name == "#default" ? "Ilość standardowa" : variant.name,
                            paidDetails: paidDetailsTxt.length ? paidDetailsTxt : null,
                            categoryTitle: orderPos.name,
                            price: moneyFormat.format(orderPos.price),
                            qty: variant.qty,
                            wholePrice: moneyFormat.format(variant.qty * orderPos.price),
                            category: orderPos._id,
                            guestsList: variant.needGuestList ? (variant.guestList ? 'ok' : 'error') : false,
                            texts: variant.needContent ? (variant.content ? 'ok' : 'error') : false 
                        });
                    }
                } else {
                    p.push({
                        orderPos,
                        neededDetailsCount,
                        providedDetailsCount,
                        name: orderPos.name,
                        price: moneyFormat.format(orderPos.price),
                        qty: orderPos.qty,
                        wholePrice: moneyFormat.format(orderPos.qty * orderPos.price),
                        guestsList: false
                    });
                }

                if (Array.isArray(orderPos.surcharges) && orderPos.surcharges.length) {
                    for (let z = 0; z < orderPos.surcharges.length; z++) {
                        p.push({
                            orderPos,
                            isPermanent: true,
                            variant: {},
                            neededDetailsCount,
                            providedDetailsCount,
                            name: orderPos.surcharges[z].name,
                            price: moneyFormat.format(orderPos.surcharges[z].value),
                            qty: 1,
                            wholePrice: moneyFormat.format(orderPos.surcharges[z].value),
                            category: orderPos._id,
                            guestsList: false,
                            texts: false 
                        });
                    }
                } else if (orderPos.surcharge && orderPos.surcharge.value) {
                    p.push({
                        orderPos,
                        isPermanent: true,
                        variant: {},
                        neededDetailsCount,
                        providedDetailsCount,
                        name: orderPos.surcharge.name,
                        price: moneyFormat.format(orderPos.surcharge.value),
                        qty: 1,
                        wholePrice: moneyFormat.format(orderPos.surcharge.value),
                        category: orderPos._id,
                        guestsList: false,
                        texts: false 
                    });
                }
            }

            return p;
        }
    },

    async created () {
        this.isLoading = true;
        let orderUUID = localStorage.getItem(config.CUSTOMER_LS_KEY);
        if (!orderUUID) {
            try {
                let cart = await Api.order.createNewCart();
                orderUUID = cart.uuid;
                localStorage.setItem(config.CUSTOMER_LS_KEY, orderUUID);
            } catch (error) {
                this.$toast.error("Niestety nie udało się utworzyć nowego koszyka");
                console.error(error);
            }
        }

        try {
            Api.setToken(orderUUID);
            let o = await Api.order.get();
            if (!o.delivery) o.delivery = {};
            this.order = o;

            let allDTypes = await Api.delivery.search({});
            allDTypes.sort((a, b) => a._priority - b._priority);
            this.deliveryTypes = allDTypes.filter(c => !c.isRemoved);

            if (!this.order || this.order.status != "cart") return this.$router.push({ name: 'order', params: {orderUUID: this.order.uuid} });
            this.orderContents = await Api.order.getContent();
            this.features = await Api.features.search({});
        } catch (error) {
            console.error(error);
        }

        let query = this.$router.currentRoute.query;
        if (query.backTo) localStorage.setItem("back-to-url", query.backTo);
        this.backUrl = localStorage.getItem("back-to-url");

        if (query.addProduct && query._s) {
            let data = {};
            let qty = null
            if (query.p_attr_id) data.id_product_attribute = query.p_attr_id;
            if (query.qty) qty = parseInt(query.qty);
            let cartPos = await this.addProductToCart(query.addProduct, query._s, data, qty);

            if (query.pkey) {
                try {
                    // dodadejmy projekt do nowo dodanej pozycji
                    await this.addDesignerProjectToCartPosition(cartPos, query.pkey);
                } catch (error) {
                    this.$toast.error("Wystąpił problem podczas dodawania projektu do zamówienia");
                }
            }

            if (query.wykropkowane && query.personalizowane) {
                await this.tryToSetQuantities(cartPos, parseInt(query.wykropkowane), parseInt(query.personalizowane));
            }

            this.$router.replace({query: null});
            // if (prod.details && Object.keys(prod.details).length) this.gotoDetails(prod._id);
            // przekieruj do edycji produktu
        }

        this.isLoading = false;
    },

    methods: {
        backToShop() {
            window.location.href = this.backUrl;
        },
        async addPersonalCartPosition(idPos) {
            let customPos = await this.$modal.show(CustomCartPosDialog);
            if (customPos) {
                let newPos = await Api.order.addCustomPos(idPos, customPos);
                let idx = this.orderContents.map(c => c._id).indexOf(idPos);
                if(idx != -1) this.$set(this.orderContents, idx, newPos);

                this.$toast.success("Zamiana zapisana");
            }
        },

        async addProductToCart(productId, productSource, sourceSpecyficData, qty) {
            let prod = await Api.order.addProduct(productId, productSource, sourceSpecyficData, qty);
            this.orderContents.push(prod);
            return prod;
        },

        async addDesignerProjectToCartPosition(cartPos, projectKey) {
            let pp = await Api.designer.addProject(cartPos._id, projectKey, cartPos.variants.map(v => v._id));
            let fontFeature = this.features.find(f => f.name.toLowerCase() == "czcionka");
            if (fontFeature && typeof cartPos.details[fontFeature._id] != 'undefined') {
                cartPos.details[fontFeature._id] = "Z projektu #" + pp.project.code;
                await Api.order.updateContent(cartPos._id, {details: cartPos.details});
            }
            for (let i = 0; i < cartPos.variants.length; i++) {
                const variant = cartPos.variants[i];
                
                // jesli warian powinien posiadac jakies tresci to zakladamy, ze zostaly
                // one podane w samym projekcie
                if (variant.needContent) {
                    variant.content = "Treści z projektu #" + pp.project.code;
                    await Api.order.updateVariant(cartPos._id, variant._id, { content: variant.content });
                }
            }
            return pp;
        },

        async tryToSetQuantities(cartPos, dottetQty, personalizedQty) {
            if (cartPos.variants.length < 2) return false;
            let dottedVariant = cartPos.variants.find(v => v.name.toLowerCase().indexOf("wykropkow") != -1);
            if (dottedVariant) {
                let updCartPos = await Api.order.updateVariant(cartPos._id, dottedVariant._id, {qty: dottetQty || 1});
                dottedVariant.qty = dottetQty;
                cartPos.qty = updCartPos.qty;
                cartPos.surcharge = updCartPos.surcharge;
                cartPos.surcharges = updCartPos.surcharges;
            }

            let persoVariant = cartPos.variants.find(v => v.name.toLowerCase().indexOf("personaliz") != -1);
            if (persoVariant) {
                let updCartPos = await Api.order.updateVariant(cartPos._id, persoVariant._id, {qty: personalizedQty || 1});
                this.$set(persoVariant, "qty", personalizedQty);
                cartPos.qty = updCartPos.qty;
                cartPos.surcharge = updCartPos.surcharge;
                cartPos.surcharges = updCartPos.surcharges;
            }
        },

        async editVariantQty(cartPos, variant) {
            let editedQty = await this.$refs.qtyedit.open('Zmień ilość sztuk', variant.qty, cartPos.price);
            if (editedQty !== false) {
                let oldQty = variant.qty;
                this.$set(variant, "qty", editedQty);

                // w odpowiedzi od API dostajemy przeliczona cala pozycje
                try {
                    let updCartPos = await Api.order.updateVariant(cartPos._id, variant._id, {qty: editedQty});
                    cartPos.qty = updCartPos.qty;
                    cartPos.surcharge = updCartPos.surcharge;
                    cartPos.surcharges = updCartPos.surcharges;
    
                    this.$toast.success("Zmiana zapisana");
                } catch (error) {
                    this.$set(variant, "qty", oldQty);
                    this.$toast.error("Nie można zmienić ilości. Minimalna ilość dla tego produktu to "+cartPos.minQty+"szt");
                }
            }
        },

        async editTextsInVariant(cartPos, variant) {
            let editedText = await this.$modal.show(TextEditDialog, {text: variant.content});
            if (editedText) {
                this.$set(variant, "content", editedText);
                await Api.order.updateVariant(cartPos._id, variant._id, {content: editedText});
                this.$toast.success("Zmiana zapisana");
            }
        },

        async editPaidDetails(cartPos) {
            let editedDetails = await this.$modal.show(PaidDetailsSelectorDialog, {basePrice: cartPos._basePrice, details: cartPos.paidDetails, features: this.features});
            if (editedDetails) {
                let resCartPos = await Api.order.updateContent(cartPos._id, {paidDetails: editedDetails});
                this.$set(cartPos, "paidDetails", resCartPos.paidDetails);
                cartPos.price = resCartPos.price;
                this.$toast.success("Zmiana zapisana");
            }
        },

        async editGuestListInVariant(cartPos, variant) {
            let editedText = await this.$modal.show(GuestListEditDialog, {text: variant.guestList, cartPosName: cartPos.name});
            if (editedText) {
                this.$set(variant, "guestList", editedText);

                let filtered = editedText.split(/\r\n|\r|\n/).filter(function (el) { return el != ""; });
                if(filtered.length > 0) this.$set(variant, "qty", filtered.length);
                let updCartPos = await Api.order.updateVariant(cartPos._id, variant._id, {
                    guestList: editedText,
                    qty: variant.qty
                });
                cartPos.qty = updCartPos.qty;
                cartPos.surcharge = updCartPos.surcharge;
                cartPos.surcharges = updCartPos.surcharges;
                this.$toast.success("Zmiana zapisana");
            }
        },

        gotoDetails(orderPosId) {
            // this.$router.push("/product-details");
            this.$router.push({
                name: 'product-details',
                params: {
                    cartPosId: orderPosId,
                    orderUUID: this.order.uuid
                }
            })
        },

        placeOrder() {
            this.details = true;
        },

        async deleteCartPos(cartPosId) {
            let idx = this.orderContents.map(pos => pos._id).indexOf(cartPosId);
            if (idx == -1) return;

            let pos = this.orderContents[idx];
            if (await this.$refs.confirm.open('Usuń produkt z koszyka', 'Czy na pewno chcesz usunąć pozycję ' + pos.name + '?', { color: 'red' })) {
                this.orderContents.splice(idx , 1);
                await Api.order.removeContent(cartPosId);
            }
        },
        async changeDelivery() {
            try {
                await Api.order.setDelivery(this.order.delivery.typeId, this.order.delivery);
                this.$toast.success("Zmiana zapisana");
            } catch (error) {
                console.log(error)
            }
        },
        getDelveryPrice(id) {
            let idx = this.deliveryTypes.map(t => t._id).indexOf(id);
            if (idx !=-1) return this.deliveryTypes[idx].priceBrutto;
            else return null;
        },
        onChange() {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => this.saveVoucher(), 800);
        },
        async saveVoucher() {
            this.isRabatLoading = true;
            this.isVoucherValid = true;
            this.voucher = null;
            try {
                this.voucher = await Api.customer.checkVoucher(this.rabatCode);
                this.$toast.success("Zastosowano rabat " + this.voucher.name);
            } catch (error) {
                this.isVoucherValid = false;
            }
            this.isRabatLoading = false;
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../sass/variables.scss';
.w-green {
    color: $w-green;
}
.b-pink {
background-color: $w-pink;
}

</style>
